import React from 'react'
import './authors.styles.scss'
import Author from './author/author.component'

const Authors = props => {
    return (
        <div className="authors">
            {
                props.authors.map((author, key) => <Author manuscriptId={props.manuscriptId} key={key} index={key} {...author} onRemove={() => props.onRemove(key)}/>)
            }
        </div>
    )
}

export default Authors