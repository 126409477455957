import { StringValidationRule, EmailValidationRule } from "../../../../data/validation/rules"
import Validation from "../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        creating: false,
        title: "",
        firstname: "",
        otherNames: "",
        email: "",
        affiliations: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.otherNames, (error) => errors.otherNames = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(EmailValidationRule, instance.state.email, (error) => errors.email = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.affiliations, (error) => errors.affiliations = error, { min: { value: 0, error: "Too short" }, max: { value: 200, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addAuthor = (instance) => {
    instance.props.onAddAuthor({
        title: instance.state.title,
        firstname: instance.state.firstname,
        otherNames: instance.state.otherNames,
        email: instance.state.email,
        affiliations: instance.state.affiliations
    })
    instance.props.onCancel()
}



const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addAuthor: () => addAuthor(instance)
    }
}

export default Worker