import React from 'react'
import { Switch, Route } from 'react-router-dom'
import EditManuscript from './edit/edit.page'

const ManuscriptController = () => {
    return (
        <div className="manuscript">
            <Switch>
                <Route path="" component={EditManuscript} />
            </Switch>
        </div>
    )
}

export default ManuscriptController