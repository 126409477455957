import React from "react";

const ManuscriptForm = (props) => {
  return (
    <form>
      <div className="inputs">
        <div className="row">
          <div className="form-label">
            <label>Choose Journal</label>
          </div>
          <div className="form-input">
            <select
              name="journalId"
              value={props.journalId}
              onChange={props.onChange}
            >
              <option value=""></option>
              {props.journals
                .filter((journal) => journal.ceased !== "1")
                .map((journal, key) => (
                  <option key={key} value={journal.id}>
                    {journal.title}
                  </option>
                ))}
            </select>
            <span className="error">{props.errors.journalId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Manuscript Title</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="title"
              value={props.title}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.title}</span>
          </div>
        </div>
      </div>
      {props.children}
      <div className="actions">
        <button type="submit" onClick={props.onSubmit}>
          {props.action}
        </button>
      </div>
    </form>
  );
};

export default ManuscriptForm;
