import React from 'react'
import './default.styles.scss'
import Content from './content/content'
import Header from './header/header'

const DefaultTemplate = (props) => {
    return (
        <div className="default-template">
            <Header/>
            <section>
                <Content>{props.children}</Content>
            </section>
        </div>
    )
}

export default DefaultTemplate