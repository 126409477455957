import React from 'react'
import iconDelete from '../../../../../assets/icons/delete-black.svg'

const File = props => {
    return (
        <div className="file">
            <div>
                <h4>{props.name}</h4>
                <p>File: {props.file.name}</p>
            </div>
            <div><img src={iconDelete} alt="" onClick={() => props.onRemove(props.index)} /></div>
        </div>
    )
}

export default File