import axios from 'axios'
import Worker from "../worker"
import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        updating: false,
        loadingJournals: false,
        showAddAuthor: false,
        showAddFile: false,
        id: "",
        title: "",
        journalId: "",
        errors: {},
        journals: [],
        authors: [],
        files: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.journalId, (error) => errors.journalId = error, { min: { value: 20, error: "Too short" }, max: { value: 45, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 700, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updateManuscript = (instance) => {
    instance.setState({
        ...instance.state,
        updating: true
    })

    let data = new FormData()
    data.append('journalId', instance.state.journalId)
    data.append('title', instance.state.title)
    data.append('authors', JSON.stringify(instance.state.authors))

    for (let i = 0; i < instance.state.files.length; i++) {
        data.append('file' + i + '-name', instance.state.files[i].name)
        data.append('file' + i, instance.state.files[i].file)
    }

    axios({
        method: 'post',
        url: API_ENDPOINT + "api/submission/manuscript/create",
        data: data
    }).then(response => handleUpdateManuscriptResponse(instance, response))
        .catch(error => {
            alert(error)
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: error
                }
            })
        })
}

const handleUpdateManuscriptResponse = (instance, response) => {
    if (response.status !== 200) {
        instance.setState({
            ...instance.state,
            updating: false,
            flag: {
                type: "error",
                text: response.data.message
            }
        })
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "#Manuscript " + response.data.data.manuscriptNumber + " submitted, our team will contact you"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const EditWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        loadJournals: Worker(instance).loadJournals,
        isValid: () => isValid(instance),
        updateManuscript: () => updateManuscript(instance)
    }
}

export default EditWorker