import iconHome from '../../../assets/icons/home.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [
        {
            text: "Dashboard",
            icon: iconHome,
            subItems: []
        },
        {
            text: "Manuscript",
            icon: iconHome,
            subItems: [
                {
                    url: "/manuscripts",
                    text: "Manuscripts"
                },
                {
                    url: "/manuscripts/create",
                    text: "Submit"
                }
            ]
        }
    ]
}

export default DefaultStore