import React from "react";
import "./header.scss";

const Header = (props) => {
  return (
    <header>
      <nav>
        <div className="container">
          <div className="content">
            <a className="brand" href="https://meritresearchjournals.org">
              <h1>Merit Research Journals</h1>
            </a>
            <ul>
              <li>
                <a href="https://meritresearchjournals.org">Home</a>
              </li>
              <li>
                <a href="https://meritresearchjournals.org/about">
                  About
                </a>
              </li>
              <li>
                <a href="https://meritresearchjournals.org/journals">
                  Journals
                </a>
              </li>
              <li>
                <a href="https://meritresearchjournals.org/authors-instruction">
                Author's Instruction
                </a>
              </li>
              <li>
                <a href="https://meritresearchjournals.org/call-for-papers">
                  Call For Paper
                </a>
              </li>
              <li>
                <a href="https://meritresearchjournals.org/contact">
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
