import React from 'react'
import iconDelete from '../../../../../assets/icons/delete-black.svg'

const Author = props => {
    return (
        <div className="author">
            <div>
                <h4>{props.title} {props.firstname} {props.otherNames} {props.index === 0? '(Corresponding Author)':''}</h4>
                <p>Email: {props.email}</p>
                <p>Affiliations: {props.affiliations}</p>
            </div>
            <div><img src={iconDelete} alt="" onClick={() => props.onRemove(props.index)}/></div>
        </div>
    )
}

export default Author