import React from 'react'
import './files.styles.scss'
import File from './file/file.component'

const Files = props => {
    return (
        <div className="files">
            {
                props.files.map((file, key) => <File manuscriptId={props.manuscriptId} key={key} index={key} {...file} onRemove={() => props.onRemove(key)}/>)
            }
        </div>
    )
}

export default Files