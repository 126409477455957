import React from 'react'
import ManuscriptForm from '../components/form/form.component'
import FormHelper from '../../../utils/FormHelper'
import EditWorker from './edit.worker'
import AddAuthor from '../components/add-author/add-author.component'
import Authors from '../components/authors/authors.component'
import Dialog from '../../../components/dialog/dialog.component'
import AddFile from '../components/add-file/add-file.component'
import Files from '../components/files/files.component'
import Loading from '../../../components/loading/loading.component'

class EditManuscript extends React.Component {

    constructor(props) {
        super(props)
        this.editWorker = EditWorker(this)
        this.state = this.editWorker.getDefaultState()
    }

    componentDidMount() {
        this.editWorker.loadJournals()
    }

    onSubmit = e => {
        e.preventDefault()
        this.editWorker.isValid() && this.editWorker.updateManuscript()
    }

    onCancel = () => {
        this.props.history.push('/manuscripts')
    }

    addToAuthors = author => {
        let authors = this.state.authors
        authors.push(author)
        this.setState({
            ...this.state,
            authors: authors
        })
    }

    addToFiles = name => {
        let files = this.state.files
        files.push(name)
        this.setState({
            ...this.state,
            files: files
        })
    }

    onRemoveAuthor = index => {
        let authors = this.state.authors.filter((author, key) => key !== index)
        this.setState({
            ...this.state,
            authors: authors
        })
    }

    onRemoveFile = index => {
        let files = this.state.files.filter((author, key) => key !== index)
        this.setState({
            ...this.state,
            files: files
        })
    }

    closeAddAuthor = () => {
        this.setState({
            ...this.state,
            showAddAuthor: false
        })
    }

    closeAddFile = () => {
        this.setState({
            ...this.state,
            showAddFile: false
        })
    }

    onNewAuthor = () => {
        this.setState({
            ...this.state,
            showAddAuthor: true
        })
    }

    onAddFile = () => {
        this.setState({
            ...this.state,
            showAddFile: true
        })
    }

    render() {
        return (
            <div className="edit">
                {(this.state.updating || this.state.loadingJournals) && <Dialog><Loading/></Dialog>}
                {this.state.showAddAuthor && <Dialog><AddAuthor manuscriptId={this.state.id} title={this.state.authors.length === 0? 'Add Corresponding Author': 'Add Author'} onAddAuthor={this.addToAuthors} onCancel={this.closeAddAuthor} /></Dialog>}
                {this.state.showAddFile && <Dialog><AddFile manuscriptId={this.state.id} onAddFile={this.addToFiles} onCancel={this.closeAddFile} /></Dialog>}
                <div className="card">
                    <div className="header">
                        <h4>Submit Manuscript</h4>
                    </div>
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <ManuscriptForm {...this.state} action="Submit" onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.onCancel}>
                        <div className="header">
                            <h4>Authors</h4>
                        </div>
                        <span className="btn" onClick={this.onNewAuthor}>{this.state.authors.length === 0? 'Add Corresponding Author': 'Add Author'}</span>
                        <Authors manuscriptId={this.state.id} authors={this.state.authors} onRemove={this.onRemoveAuthor} />
                        <div className="header">
                            <h4>Files</h4>
                        </div>
                        <span className="btn" onClick={this.onAddFile}>Add File</span>
                        <Files manuscriptId={this.state.id} files={this.state.files} onRemove={this.onRemoveFile} />
                    </ManuscriptForm>
                </div>
            </div>
        )
    }
}

export default EditManuscript