import React from 'react'

const Form = props => {
    return (
        <form onSubmit={props.onSubmit}>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Title</label>
                    </div>
                    <div className="form-input">
                        <select name="title" value={props.title} onChange={props.onChange}>
                            {
                                titleOptions.map((option, key) => <option key={key} value={option.value}>{option.label}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.title}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Firstname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="firstname" value={props.firstname} onChange={props.onChange} />
                        <span className="error">{props.errors.firstname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Other Names</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="otherNames" value={props.otherNames} onChange={props.onChange} />
                        <span className="error">{props.errors.otherNames}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Email</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="email" value={props.email} onChange={props.onChange} />
                        <span className="error">{props.errors.email}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Affiliations</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="affiliations" value={props.affiliations} onChange={props.onChange} />
                        <span className="error">{props.errors.affiliations}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="submit">Save</button>
                <button type="reset" onClick={props.onCancel}>Cancel</button>
            </div>
        </form>
    )
}

const titleOptions = [
    {
        label: "",
        value: ""
    },
    {
        label: "Prof",
        value: "Prof"
    },
    {
        label: "Dr",
        value: "Dr"
    },
    {
        label: "Mr",
        value: "Mr"
    },
    {
        label: "Mrs",
        value: "Mrs"
    },
    {
        label: "Miss",
        value: "Miss"
    },
    {
        label: "Engr",
        value: "Engr"
    }
]

export default Form