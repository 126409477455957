import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import './app.styles.scss'
import DefaultTemplate from './templates/default/default.template'
import ManuscriptController from './pages/manuscript/controller'

const AppController = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
        <Route path="/" component={() => <DefaultTemplate><ManuscriptController /></DefaultTemplate>} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default AppController
