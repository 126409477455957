import { StringValidationRule, FileValidationRule } from "../../../../data/validation/rules"
import Validation from "../../../../data/validation/validation"

const getDefaultState = () => {
    return {
        creating: false,
        name: "",
        file: null,
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 25, error: "Invalid" } })
    validation.addValidationRule(FileValidationRule, instance.state.file, (error) => errors.file = error, { allowNull: false })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const addFile = (instance) => {
    let data = new FormData()
    data.append('manuscriptId', instance.props.manuscriptId)
    data.append('name', instance.state.name)
    data.append('file', instance.state.file)

    instance.props.onAddFile({
        name: instance.state.name,
        file: instance.state.file
    })
    instance.props.onCancel()

}

const Worker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        addFile: () => addFile(instance)
    }
}

export default Worker